import React from 'react';
import PasswordIcon from '../../../assets/images/password-icon.png';
import { Container, Button, Image, Card } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

interface ForgotPasswordFormValues {
    email: string;
}

function ForgotPassword() {
    const initialValues: ForgotPasswordFormValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });
    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className='auth-card-4 p-5'>
                    <div className='text-center'>
                        <Image src={PasswordIcon} className='' />
                        <h1 className=' auth-text-1 my-3'>Reset password</h1>
                        <p className='auth-text-2'>
                            Enter your email address and we'll  <br />
                            send you a link to reset your password.
                        </p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values: ForgotPasswordFormValues, { setSubmitting }) => {
                                console.log("Submitting email:", values.email);
                            }}

                        >
                            {({ errors, touched, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className='text-center mt-2'>

                                        <Field
                                            type='email'
                                            name='email'
                                            placeholder='Email'
                                            className={`input-field-2 ${touched.email && errors.email ? 'is-invalid' : ''
                                                }`}
                                            required
                                        />
                                    </div>
                                    <Link to="/reset-password" className='link-styles'>
                                        <div className='d-grid mt-3 sign-up-btn'>
                                            <Button type='button'
                                                className='bg-color-1 border-0'
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                    </Link>
                                    <div className='auth-text-2 mt-3'>
                                        <Link to='/sign-in' className='link-styles'>
                                            Back to <span className='text-color-1'> Sign in </span>
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>

        </Container>)
}

export default ForgotPassword