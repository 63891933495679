import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Nav } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BackBtn from "../../../assets/images/back-btn.png";
import Arrow from "../../../assets/images/arrow.png";
import NextBtn from "../../../assets/images/next-btn.png";
import { Link } from "react-router-dom";

interface WeightValues {
    weight: number;
}

function Weight() {
    const [activeTab, setActiveTab] = useState<string>("kg");
    const [selectedWeight, setSelectedWeight] = useState<number | null>(1);
    const weights = Array.from({ length: 300 }, (_, i) => i + 1);

    useEffect(() => {
        setActiveTab("kg");
    }, []);

    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTab(key);
        }
    };

    const handleWeightClick = (weight: number) => {
        setSelectedWeight(weight);
    };

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className="auth-card-3 text-center">
                    <Link to="/age" className='d-inline-flex justify-content-start px-2'>
                        <img src={BackBtn} alt="back" />
                    </Link>
                    <h4 className="auth-text-1 mt-2">What’s your weight?</h4>
                    <p className="auth-text-2 text-color-2">Please enter your current weight.</p>
                    <div className='d-flex justify-content-center align-items-center'>
                        <Nav activeKey={activeTab} onSelect={handleTabSelect} className="nav justify-content-center">
                            <Nav.Item>
                                <Nav.Link
                                    className={`nav-link-1 ${activeTab === "kg" ? "active" : ""}`} // Add active class conditionally
                                    eventKey="kg"
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    KG
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className={`nav-link-2 ${activeTab === "lb" ? "active" : ""}`} // Add active class conditionally
                                    eventKey="lb"
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    LB
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div>
                        <Formik
                            initialValues={{ weight: 0 }}
                            validationSchema={Yup.object().shape({
                                weight: Yup.number().required("Weight is required"),
                            })}
                            onSubmit={(values: WeightValues, { setSubmitting }) => {
                                console.log(values);
                            }}
                        >
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        {selectedWeight !== null && (
                                            <div className="text-center mt-3">
                                                <h3 className='selected-age'>{selectedWeight}</h3>
                                            </div>
                                        )}
                                        <div className='mt-4'>
                                            <img src={Arrow} alt='arrow' />
                                        </div>
                                        <div className="age-selector-container mt-5">
                                            <div className="age-items">
                                                {weights.map((weight) => (
                                                    <Button
                                                        key={weight}
                                                        variant={selectedWeight === weight ? "#008080" : "#008080"}
                                                        className="age-button"
                                                        onClick={() => handleWeightClick(weight)}
                                                    >
                                                        {weight}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3 p-4">
                                        <div>
                                            <p className="auth-text-3">
                                                To create your personalized plan, we use <br />
                                                BMI to calculate your calorie budget. This <br />
                                                requires your weight, height, biological sex, <br />
                                                and age as inputs
                                            </p>
                                        </div>
                                        <div>
                                            <Link to="/height">
                                                <img src={NextBtn} alt="next" />
                                            </Link>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Container>
    );
}

export default Weight;
