import React from 'react'
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function LandingPage() {
    return (
        <div>
            <Container fluid>
                <div className="home-bg d-none d-md-block"></div>
                <div className='home-text'>
                    <h3 className='home-header'>NUTIENT</h3>
                    <p className='home-p text-center'>Track your calories, reach your <br /> fitness goals, and live a <br /> healthier life</p>
                    <Link to="/sign-up" className='link-style'>
                        <Button type='submit' className='home-btn'>Join Waitlist Now</Button>
                    </Link>
                </div>
            </Container>
        </div>
    )
}

export default LandingPage;