import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Nav, Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BackBtn from "../../../assets/images/back-btn.png";
import Arrow from "../../../assets/images/right-arrow.png";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

interface HeightValues {
    height: number;
}

function Height() {
    const [activeTab, setActiveTab] = useState<string>("cm");
    const [selectedHeight, setSelectedHeight] = useState<number | null>(1);
    const heights = Array.from({ length: 300 }, (_, i) => i + 1);

    useEffect(() => {
        setActiveTab("cm");
    }, []);

    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTab(key);
        }
    };

    const handleHeightClick = (height: number) => {
        setSelectedHeight(height);
    };

    const navigate = useNavigate();

    const handleCreateAccount = () => {
        navigate('/email-verification');
    };

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className="auth-card-3 text-center px-3">
                    <Link to="/weight" className='d-inline-flex justify-content-start px-2'>
                        <img src={BackBtn} alt="back" />
                    </Link>
                    <h4 className="auth-text-1 mt-2">What’s your height?</h4>
                    <p className="auth-text-2 text-color-2">Please enter your current height.</p>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Nav activeKey={activeTab} onSelect={handleTabSelect} className="nav justify-content-center">
                            <Nav.Item>
                                <Nav.Link
                                    className={`nav-link-1 ${activeTab === "cm" ? "active" : ""}`}
                                    eventKey="cm"
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    CM
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className={`nav-link-2 ${activeTab === "ft" ? "active" : ""}`}
                                    eventKey="ft"
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    FT
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                    <Formik
                        initialValues={{ height: 0 }}
                        validationSchema={Yup.object().shape({
                            height: Yup.number().required("Height is required"),
                        })}
                        onSubmit={(values: HeightValues, { setSubmitting }) => {
                            console.log(values);
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <div>
                                    <Row className='mt-4'>
                                        <Col md={7} className='mt-5 pt-5'>
                                            {selectedHeight !== null && (
                                                <h3 className='selected-age'>{selectedHeight}</h3>
                                            )}
                                            <div>
                                                <p className="auth-text-3 mt-5">
                                                    To create your personalized plan, we use <br />
                                                    BMI to calculate your calorie budget. This <br />
                                                    requires your weight, height, biological sex, <br />
                                                    and age as inputs
                                                </p>
                                            </div>
                                        </Col>
                                        <Col md={1} className='mt-5 pt-5'>
                                            <img src={Arrow} alt='arrow' className='mt-4' />
                                        </Col>
                                        <Col md={4}>
                                            <div className="height-selector-container">
                                                {heights.map((height) => (
                                                    <Button
                                                        key={height}
                                                        variant={selectedHeight === height ? "#008080" : "#008080"}
                                                        className="height-button"
                                                        onClick={() => handleHeightClick(height)}
                                                    >
                                                        {height}
                                                    </Button>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-grid mt-4 sign-up-btn">
                                    <Button
                                        onClick={handleCreateAccount}
                                        type="submit"
                                        className="border-0 bg-color-1 "
                                    >
                                        <span className="text-center">Create Account</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </div>
        </Container>
    );
}

export default Height;
