import React from 'react'
import Logo from "../../../assets/images/logo.png"
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CloseBtn from "../../../assets/images/close-btn.png"
import NextBtn from "../../../assets/images/next-btn.png"
import { Link } from "react-router-dom";

interface SignUpValues {
    first_name: string;
    last_name: string;
    email: string;
    sex: string;
    country: string;
    ethnicity: string;
    password: string;
    confirm_password: string;
    terms: boolean;
}

function SignUp() {
    return (
        <Container fluid>
            <div className="auth-bg">
                <Card
                    className="auth-card text-center"
                >
                    <Link to="/" className='d-inline-flex justify-content-end'>
                        <img src={CloseBtn} alt="close" />
                    </Link>
                    <div className='text-center'>
                        <Image src={Logo} width={97} height={120} />
                    </div>
                    <h4 className="auth-text-1 mt-2">Create An Account</h4>
                    <div className='text-start mt-2'>
                        <Formik
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                email: "",
                                sex: "",
                                country: "",
                                ethnicity: "",
                                password: "",
                                confirm_password: "",
                                terms: false,
                            }}
                            validationSchema={Yup.object().shape({
                                first_name: Yup.string().required("First name is required"),
                                last_name: Yup.string().required("Last name is required"),
                                email: Yup.string()
                                    .email("Invalid email")
                                    .required("Email is required"),
                                sex: Yup.string().required("Sex is required"),
                                country: Yup.string().required("Country is required"),
                                ethnicity: Yup.string().required("  Ethnicity is required"),
                                password: Yup.string()
                                    .required("No password provided.")
                                    .min(8, "Must be at least 8 characters"),
                                confirm_password: Yup.string()
                                    .oneOf([Yup.ref("password"), ""], "Passwords do not match")
                                    .required("Please confirm password."),
                                terms: Yup.boolean().oneOf(
                                    [true],
                                    "You must accept the terms and conditions",
                                ),

                            })}
                            onSubmit={(values: SignUpValues, { setSubmitting }) => {
                                console.log(values)
                            }}
                            validateOnChange
                            validateOnBlur
                        >
                            {({
                                errors,
                                touched,
                                handleSubmit,
                                isSubmitting,
                                values,
                                handleChange,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-2">
                                        <Col md={6}>
                                            <Field
                                                type="text"
                                                name="first_name"
                                                placeholder="First Name *"
                                                className={`input-field ${touched.first_name && errors.first_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name *"
                                                className={`input-field ${touched.last_name && errors.last_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md={6}>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Email Address *"
                                                className={`input-field ${touched.email && errors.email ? "is-invalid" : ""
                                                    }`}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field
                                                type="text"
                                                name="sex"
                                                placeholder="Sex *"
                                                className={`input-field ${touched.sex && errors.sex
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md={6}>
                                            <Field
                                                type="text"
                                                name="country"
                                                placeholder="Country *"
                                                className={`input-field ${touched.country && errors.country
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field
                                                type="text"
                                                name="ethnicity"
                                                placeholder="Ethnicity *"
                                                className={`input-field ${touched.ethnicity && errors.ethnicity
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md={6}>
                                            <Field
                                                type="password"
                                                name="password"
                                                placeholder="Password *"
                                                className={`input-field ${touched.password && errors.password
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field
                                                type="password"
                                                name="confirm_password"
                                                placeholder="Confirm Password *"
                                                className={`input-field ${touched.confirm_password && errors.confirm_password
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-2 auth-text-2 ">
                                            <span>
                                                <Field type="checkbox" name="terms" />
                                                <span className='mx-3'>
                                                    I accept Nutrient's{" "}
                                                    <span className="text-color-1">Privacy </span> and{" "}
                                                    <span className="text-color-1"> Term of Use </span>
                                                </span>
                                            </span>
                                            {touched.terms && errors.terms && (
                                                <div className="invalid-feedback">{errors.terms}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <div className="text-center auth-text-2 mt-4">
                                        <Link to="/sign-in" className="link-styles">
                                            Already have an account?{" "}
                                            <span className="text-color-1"> Sign In </span>
                                        </Link>

                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Link to="/age">
                                            <img src={NextBtn} alt="next" />
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Container>
    )
}

export default SignUp