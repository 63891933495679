import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "./assets/style/style.css";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from "./pages/landing-page/landing-page";
import SignUp from './pages/auth/sign-up/sign-up';
import Age from './pages/auth/sign-up/age';
import Weight from './pages/auth/sign-up/weight';
import Height from './pages/auth/sign-up/height';
import SignIn from './pages/auth/sign-in';
import EmailVerification from './pages/auth/sign-up/verification/email-verification';
import VerificationSuccessful from './pages/auth/sign-up/verification/verification-successful';
import ForgotPassword from './pages/auth/forgot-password/forgot-password';
import ResetPassword from './pages/auth/forgot-password/reset-password';
import ResetSuccessful from './pages/auth/forgot-password/reset-successful';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/age" element={<Age />} />
          <Route path="/weight" element={<Weight />} />
          <Route path="/height" element={<Height />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/verification-successful" element={<VerificationSuccessful />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-successful" element={<ResetSuccessful />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
