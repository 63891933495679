import React from 'react';
import PasswordIcon from '../../../assets/images/password-icon.png';
import { Container, Button, Image, Card } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

interface ResetPasswordFormValues {
    password: string;
    password_confirmation: string;
}

function ResetPassword() {
    const initialValues: ResetPasswordFormValues = {
        password: '',
        password_confirmation: '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), ""], 'Passwords do not match')
            .required('Please confirm password.'),
    });
    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className='auth-card-4 px-5 py-2'>
                    <div className='text-center'>
                        <Image src={PasswordIcon} className='' />
                        <h1 className=' auth-text-1 my-2'>Reset password</h1>
                        <p className='auth-text-2'>
                            Enter your email address and we'll  <br />
                            send you a link to reset your password.
                        </p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values: ResetPasswordFormValues, { setSubmitting }) => {
                                console.log("Submitting email:", values);
                            }}

                        >
                            {({ errors, touched, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className='text-center mt-2'>

                                        <Field
                                            type='password'
                                            name='password'
                                            placeholder='Password'
                                            className={`input-field-2 ${touched.password && errors.password ? 'is-invalid' : ''
                                                }`}
                                            required
                                        />
                                    </div>
                                    <div className='text-center mt-2'>

                                        <Field
                                            type='password'
                                            name='password_confirmation'
                                            placeholder='Re-Enter Password'
                                            className={`input-field-2 ${touched.password_confirmation && errors.password_confirmation ? 'is-invalid' : ''
                                                }`}
                                            required
                                        />
                                    </div>
                                    <Link to="/reset-successful">
                                        <div className='d-grid mt-3 sign-up-btn'>
                                            <Button type='button'
                                                className='bg-color-1 border-0'
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                    </Link>
                                    <div className='auth-text-2 mt-3 pb-3'>
                                        <Link to='/sign-in' className='link-styles'>
                                            Back to <span className='text-color-1'> Sign in </span>
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>

        </Container>)
}

export default ResetPassword