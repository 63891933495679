import React, { useState } from 'react';
import { Container, Card, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BackBtn from "../../../assets/images/back-btn.png"
import Arrow from "../../../assets/images/arrow.png"
import NextBtn from "../../../assets/images/next-btn.png"
import { Link } from "react-router-dom";

interface AgeValues {
    age: number;
}

function Age() {
    const [selectedAge, setSelectedAge] = useState(1);
    const ages = Array.from({ length: 100 }, (_, i) => i + 1);

    const handleAgeClick = (age: any) => {
        setSelectedAge(age);
    };
    return (
        <Container fluid>
            <div className="auth-bg">
                <Card
                    className="auth-card-3 text-center"
                >
                    <Link to="/sign-up" className='d-inline-flex justify-content-start px-2'>
                        <img src={BackBtn} alt="back" />
                    </Link>
                    <h4 className="auth-text-1 mt-2">What’s your age?</h4>
                    <p className="auth-text-2 text-color-2">Please enter your current age.</p>
                    <div>
                        <Formik
                            initialValues={{
                                age: 0,
                            }}
                            validationSchema={Yup.object().shape({
                                age: Yup.string()
                                    .required("Age is required"),
                            })}
                            onSubmit={(values: AgeValues, { setSubmitting }) => {
                                console.log(values)
                            }}>
                            {({
                                handleSubmit,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        {selectedAge && (
                                            <div className="text-center">
                                                <h3 className='selected-age'>{selectedAge || 0}</h3>
                                            </div>
                                        )}
                                        <div className='mt-5'>
                                            <img src={Arrow} alt='arrow' />
                                        </div>
                                        <div className="age-selector-container mt-5">
                                            <div className="age-items">
                                                {ages.map((age) => (
                                                    <Button
                                                        key={age}
                                                        variant={selectedAge === age ? "#008080" : "#008080"}
                                                        className="age-button"
                                                        onClick={() => handleAgeClick(age)}
                                                    >
                                                        {age}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3 p-4">
                                        <div>
                                            <p className="auth-text-3">
                                                To create your personalized plan, we use <br />
                                                BMI to calculate your calorie budget. This <br />
                                                requires your weight, height, biological sex, <br />
                                                and age as inputs
                                            </p>
                                        </div>
                                        <div>
                                            <Link to="/weight">
                                                <img src={NextBtn} alt="next" />
                                            </Link>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Container>
    )
}

export default Age